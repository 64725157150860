<template>
  <main class="default-new">
    <div class="none"></div>
    <div class="opacity-bg"></div>
    <router-view></router-view>
  </main>
</template>

<script>
export default {
  name: 'news'
}
</script>

<style lang="scss" scoped>
  .default-new {
    position: relative;
    width: 100%;
    background: url("../../assets/images/sea-burial/new-sea-index-bg.png")no-repeat top #ffffff;
    .none {
      height: 340px;
    }
    .opacity-bg {
      position: absolute;
      top: 340px;
      width: 100%;
      height: 662px;
      left: 0;
      z-index: 0;
      background: url("../../assets/images/index/new-index-bg.png")no-repeat center;
      background-size: 100% 100%;
      opacity: 0.8;
    }
  }
</style>
